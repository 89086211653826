import React, { Suspense } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import Loader from './Loader'

function Layout({ children }) {
  return (
    <div className='bento-container'>
      {children.map((_, i) => (
        // <div key={i} className='bento-card'>
        <div key={i} className={`bento-card ${(i + 1) % 4 < 2 ? 'lg:col-span-2' : ''}`}>
          <Suspense fallback={<Loader />}>
            <ErrorBoundary fallback={<Loader />}>{_}</ErrorBoundary>
          </Suspense>
        </div>
      ))}
    </div>
  )
}

export default Layout
