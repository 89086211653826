import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import resourcesToBackend from 'i18next-resources-to-backend'

i18n
  .use(initReactI18next) // Bind i18n to React
  .use(resourcesToBackend((language, namespace) => import(`./locales/${language}.json`)))
  .init({
    fallbackLng: 'pl',
    debug: false,
    interpolation: {
      escapeValue: false // React already escapes by default
    }
  })

export default i18n
