import React from 'react'
import { useTranslation } from 'react-i18next'
import SocialMedias from './SocialMedias'

function generateTimeline(artists, t) {
  return (
    <ul className='timeline with-scrollbar'>
      {artists.map((artist, index) => (
        <li key={artist.name}>
          {/* start the timeline line */}
          {index === 0 || artist.break_before ? null : <hr />}
          <div className='timeline-start'>{artist.hour}</div>
          <div className='timeline-middle'>
            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='currentColor' className='h-5 w-5'>
              <path
                fillRule='evenodd'
                d='M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z'
                clipRule='evenodd'
              />
            </svg>
          </div>
          <div
            className='timeline-end timeline-box'
            onClick={() => document.getElementById(`my_modal_${artist.name}`).showModal()}>
            <span className='flex flex-col align-middle items-center p-1'>
              <span className='artist-name'>{artist.name}</span> <span className='artist-minor'>({artist.title})</span>
            </span>
            {/* modal info */}
            <dialog id={`my_modal_${artist.name}`} className='modal'>
              <div className='modal-box'>
                <span>{artist.links ? <SocialMedias linksArray={artist.links} /> : null}</span>
                <div className='flex flex-col justify-evenly items-center mt-2'>
                  {artist.avatar ? (
                    <div className='avatar'>
                      <div className='w-48 rounded-xl mb-4'>
                        <img alt={`${artist.name} avatar`} src={require(`../assets/avatars/${artist.avatar}`)} />
                      </div>
                    </div>
                  ) : null}
                  <h2 className='font-bold text-lg'>{artist.name}</h2>
                </div>
                <p className='py-4 prose'>{t(artist.tr_description)}</p>
                <div className='modal-action'>
                  <form method='dialog'>
                    {/* if there is a button in form, it will close the modal */}
                    <button className='btn btn-sm x-button'>✕</button>
                    <button className='btn'>{t('close')}</button>
                  </form>
                </div>
              </div>
            </dialog>
          </div>
          {/* end the timeline line */}
          {index === artists.length - 1 || artist.break_after ? null : <hr />}
        </li>
      ))}
    </ul>
  )
}

function EventInfo(props) {
  const eventData = props.eventData
  const { t } = useTranslation()

  return (
    <div className='event-info'>
      <div className='with-padding -mb-8'>
        <p className='event-date'>
          {t('place')}: {eventData.place}
        </p>
        <h2 className='card-title'>{t(eventData.tr_title)}</h2>
        <p className='event-date'>
          {t('date')}: {eventData.date}
        </p>
        <p className='event-description'>{t(eventData.tr_description)}</p>
      </div>
      {generateTimeline(eventData.artists, t)}
      <div className='with-padding pb-4'>
        <p className='minor-text'>{t('click_artist')}</p>
        <p className='minor-text'>{t('help_scroll')}</p>
      </div>
    </div>
  )
}

export default EventInfo
