function getDomainFromUrl(url) {
  let toRet = 'Unknown domain'
  try {
    const { hostname } = new URL(url)
    const domainParts = hostname.split('.')
    const domain = domainParts.slice(-2).join('.')
    toRet = domain
  } catch (error) {
    console.error('Invalid URL', error)
  }

  if (toRet === 'facebook.com') {
    return (
      <svg className='h-6 w-6' fill='currentColor' viewBox='0 0 24 24'>
        <path
          fillRule='evenodd'
          d='M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z'
          clipRule='evenodd'
        />
      </svg>
    )
  }

  if (toRet === 'instagram.com') {
    return (
      <svg className='h-6 w-6' fill='currentColor' viewBox='0 0 24 24'>
        <path
          fillRule='evenodd'
          d='M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z'
          clipRule='evenodd'
        />
      </svg>
    )
  }

  if (toRet === 'twitter.com') {
    return (
      <svg className='h-6 w-6' fill='currentColor' viewBox='0 0 24 24'>
        <path d='M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84' />
      </svg>
    )
  }

  if (toRet === 'soundcloud.com') {
    return (
      <svg className='h-6 w-6' fill='currentColor' viewBox='0 0 50 50'>
        <path d='M 30 11 C 26.398438 11 23 12.789063 21 15.6875 L 21 36 L 42.5 36 C 46.601563 36 50 32.601563 50 28.5 C 50 24.398438 46.601563 21 42.5 21 C 42 21 41.5 21.085938 41 21.1875 C 40.5 15.488281 35.800781 11 30 11 Z M 17 16 C 16.300781 16 15.601563 16.085938 15 16.1875 L 15 36 L 17 36 Z M 18 16 L 18 36 L 20 36 L 20 16.5 C 19.398438 16.300781 18.699219 16.101563 18 16 Z M 14 16.5 C 13.300781 16.800781 12.601563 17.101563 12 17.5 L 12 36 L 14 36 Z M 11 18.3125 C 10.199219 19.011719 9.5 19.90625 9 20.90625 L 9 36 L 11 36 Z M 6.5 22 C 6.324219 22.011719 6.148438 22.042969 6 22.09375 L 6 35.90625 C 6.300781 36.007813 6.699219 36 7 36 L 8 36 L 8 22.09375 C 7.699219 21.992188 7.300781 22 7 22 C 6.851563 22 6.675781 21.988281 6.5 22 Z M 5 22.3125 C 4.300781 22.511719 3.601563 22.8125 3 23.3125 L 3 34.6875 C 3.601563 35.085938 4.300781 35.488281 5 35.6875 Z M 2 24.09375 C 0.800781 25.394531 0 27.101563 0 29 C 0 30.898438 0.800781 32.605469 2 33.90625 Z'></path>
      </svg>
    )
  }

  if (toRet === 'youtube.com') {
    return (
      <svg className='h-6 w-6' fill='currentColor' viewBox='0 0 50 50'>
        <path d='M 44.898438 14.5 C 44.5 12.300781 42.601563 10.699219 40.398438 10.199219 C 37.101563 9.5 31 9 24.398438 9 C 17.800781 9 11.601563 9.5 8.300781 10.199219 C 6.101563 10.699219 4.199219 12.199219 3.800781 14.5 C 3.398438 17 3 20.5 3 25 C 3 29.5 3.398438 33 3.898438 35.5 C 4.300781 37.699219 6.199219 39.300781 8.398438 39.800781 C 11.898438 40.5 17.898438 41 24.5 41 C 31.101563 41 37.101563 40.5 40.601563 39.800781 C 42.800781 39.300781 44.699219 37.800781 45.101563 35.5 C 45.5 33 46 29.398438 46.101563 25 C 45.898438 20.5 45.398438 17 44.898438 14.5 Z M 19 32 L 19 18 L 31.199219 25 Z'></path>
      </svg>
    )
  }

  if (toRet === 'bandcamp.com') {
    return (
      <svg className='h-6 w-6' fill='currentColor' viewBox='0 0 50 50'>
        <path d='M 8.5957031 17 L 0 33 L 18.404297 33 L 27 17 L 8.5957031 17 z M 27 17 L 27 32.693359 L 29.373047 32.693359 L 29.373047 31.132812 L 29.416016 31.132812 C 30.164016 32.627813 31.813562 33 33.351562 33 C 36.715563 33 38.253906 30.120406 38.253906 27.066406 C 38.253906 23.747406 36.515594 21.021484 32.933594 21.021484 C 31.658594 21.021484 30.229828 21.703734 29.548828 22.802734 L 29.505859 22.802734 L 29.505859 17 L 27 17 z M 44.878906 21.021484 C 41.141906 21.021484 39.273438 23.704812 39.273438 27.132812 C 39.273437 30.473813 41.230016 33 44.791016 33 C 47.714016 33 49.538 31.372734 50 28.427734 L 47.494141 28.427734 C 47.251141 30.054734 46.241016 31.023438 44.791016 31.023438 C 42.615016 31.023437 41.779297 29.022813 41.779297 27.132812 C 41.779297 23.682813 43.603391 23 44.900391 23 C 46.285391 23 47.296141 23.748812 47.494141 25.132812 L 47.498047 25.132812 L 50 25.132812 C 49.759 22.341813 47.515906 21.021484 44.878906 21.021484 z M 32.605469 23 C 34.669469 23 35.747047 24.955484 35.748047 27.021484 C 35.748047 28.999484 34.891469 31.021484 32.605469 31.021484 C 30.583469 31.021484 29.417969 29.306484 29.417969 27.021484 C 29.417969 24.604484 30.496469 23 32.605469 23 z'></path>
      </svg>
    )
  }

  return toRet
}
function SocialMedias(props) {
  return (
    <div className='social-media-links'>
      {props.linksArray.map((link) => (
        <span key={link}>
          <a key={link} href={link} target='_blank' rel='noreferrer'>
            {getDomainFromUrl(link)}
          </a>
        </span>
      ))}
    </div>
  )
}

export default SocialMedias
