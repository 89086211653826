import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

function Calendar({ schedule }) {
  schedule = {
    'Sat 10.08': {
      exhbition_title: {
        time: '16:00-18:00',
        place: 'KMWTW',
        entry: '20 PLN'
      },
      opening_party: {
        time: '18:00-3:00',
        place: 'Betel',
        artists: ['Mala Herba', 'Diakrytyki', 'Filip Lech'],
        entry: '60 PLN'
      }
    },
    'Sun 11.08': {
      exhbition_title: {
        time: '16:00-20:00',
        place: 'KMWTW',
        entry: 'free',
        artists: ['miejsca_mocy_off']
      }
    },
    'Mon 12.08': {
      exhbition_title: {
        time: '16:00-20:00',
        place: 'KMWTW',
        entry: 'free',
        artists: ['miejsca_mocy_off']
      },
      Spektakl: {
        time: '18:00-19:00',
        place: 'KMWTW',
        entry: 'free',
        artists: ['Transtopia']
      }
    },
    'Tue 13.08': {
      exhbition_title: {
        time: '16:00-20:00',
        place: 'KMWTW',
        entry: 'free',
        artists: ['miejsca_mocy_off']
      }
    },
    'Wed 14.08': {
      exhbition_title: {
        time: '16:00-20:00',
        place: 'KMWTW',
        entry: 'free',
        artists: ['miejsca_mocy_off']
      },
      wednesday_livesets: {
        time: '18:00',
        place: 'KMWTW',
        artists: ['Amelia Skrzypek', 'j.inhale'],
        entry: '30 PLN'
      }
    },
    'Thu 15.08': {
      exhbition_title: {
        time: '16:00-20:00',
        place: 'KMWTW',
        entry: 'free'
      },
      closing_livesets: {
        time: '16:00',
        place: 'KMWTW',
        entry: '30 PLN',
        artists: ['ehh hahah', 'Kamil Kukla'],
        rowSpan: 2
      },
      closing_party: {
        time: '18:00',
        place: 'Święta Krowa',
        artists: ['Artysta 7', 'Artysta 8']
      }
    }
  }
  const { t } = useTranslation()
  const days = Object.keys(schedule)

  const generateModalInfo = (eventName, eventInfo) => {
    return (
      <div className='modal-box'>
        {/* <span>{artist.links ? <SocialMedias linksArray={artist.links} /> : null}</span> */}
        <h3 className='font-bold text-lg'>{t(eventName)}</h3>
        {Object.keys(eventInfo).map((key) => (
          <p key={key}>
            {t(key)}: {t(eventInfo[key])}
          </p>
          // TODO calendar links https://parcel.io/tools/calendar
        ))}
        <div className='modal-action'>
          <form method='dialog'>
            <button className='btn'>{t('close')}</button>
          </form>
        </div>
      </div>
    )
  }

  const generateDateTables = () => {
    return days.map((day) => {
      const eventList = schedule[day] // Get events for this day

      return (
        <table key={day}>
          <thead>
            <tr>
              <th colSpan='4'>{day}</th>
            </tr>
          </thead>
          <tbody>
            {eventList &&
              Object.entries(eventList).map(([eventName, eventData], index) => (
                <tr key={eventName} onClick={() => document.getElementById(`my_modal_${eventName}`).showModal()}>
                  <td>
                    <div className='exhibition-art' key={index}>
                      <span className='artist-name'>{t(eventName)}</span> {/* modal info */}
                      <dialog id={`my_modal_${eventName}`} className='modal'>
                        {generateModalInfo(eventName, eventData)}
                      </dialog>
                    </div>
                    {eventData.artists && (
                      <ul className='text-xs mt-2'>
                        {eventData.artists.map((artist, index) => (
                          <li key={index} className='pb-2'>
                            {t(artist)}
                          </li>
                        ))}
                      </ul>
                    )}
                  </td>
                  <td>
                    {eventData.time} {t('at')} {eventData.place}
                  </td>
                  {eventData.entry && eventData.entry !== 'free' && (
                    <td rowSpan={eventData.rowSpan ? eventData.rowSpan : 1}>
                      <span className='badge badge-lg badge-success text-sm truncate text-base-100'>
                        {eventData.entry}
                      </span>
                    </td>
                  )}
                </tr>
              ))}
          </tbody>
        </table>
      )
    })
  }

  return (
    <div className='calendar'>
      <h3 className='card-title'>{<Trans t={t} i18nKey='calendar_title' />}</h3>
      <div id='calendar-table-container' className='with-scrollbar'>
        {generateDateTables()}
      </div>
      <div className='card-footer'>
        <p className='minor-text'>{t('help_scroll')}</p>
      </div>
    </div>
  )
}

export default Calendar
