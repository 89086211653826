import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

function Loader() {
  const { t } = useTranslation()

  return (
    <div className='p-4 flex flex-col items-center content-center cursor-wait'>
      <h1 className='card-title'>{<Trans i18nKey='loader_info' t={t} />}</h1>
      <span className='loading loading-spinner loading-lg'></span>
    </div>
  )
}

export default Loader
