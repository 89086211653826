import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import L from 'leaflet'

delete L.Icon.Default.prototype._getIconUrl

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('../assets/map/place_shadow.png'),
  iconUrl: require('../assets/map/place_shadow.png'),
  shadowUrl: require('../assets/map/place.png')
})

function buildPopupContent(name, address, googleMapsLink) {
  return (
    <Popup>
      {name} <br /> {address} <br />
      <a href={googleMapsLink} className='flex'>
        Google Maps
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='currentColor' className='size-4 ml-1'>
          <path
            fillRule='evenodd'
            d='M15.75 2.25H21a.75.75 0 0 1 .75.75v5.25a.75.75 0 0 1-1.5 0V4.81L8.03 17.03a.75.75 0 0 1-1.06-1.06L19.19 3.75h-3.44a.75.75 0 0 1 0-1.5Zm-10.5 4.5a1.5 1.5 0 0 0-1.5 1.5v10.5a1.5 1.5 0 0 0 1.5 1.5h10.5a1.5 1.5 0 0 0 1.5-1.5V10.5a.75.75 0 0 1 1.5 0v8.25a3 3 0 0 1-3 3H5.25a3 3 0 0 1-3-3V8.25a3 3 0 0 1 3-3h8.25a.75.75 0 0 1 0 1.5H5.25Z'
            clipRule='evenodd'
          />
        </svg>
      </a>
    </Popup>
  )
}

function MyMap() {
  const kmwtw = [50.058346239110605, 19.932609199541726]
  const betel = [50.06354261832181, 19.934644398373166]
  const swietakrowa = [50.05826705152039, 19.926957954708435]
  const between = [(kmwtw[0] + betel[0] + swietakrowa[0]) / 3, (kmwtw[1] + betel[1] + swietakrowa[1]) / 3]

  return (
    // TODO can dragging be disabled only on mobile?
    <MapContainer id='map' center={between} zoom={14} scrollWheelZoom={true} dragging={false}>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
      />
      <Marker position={kmwtw}>
        {buildPopupContent(
          'KMWTW - open artist run space',
          'ul. Zwierzyniecka 4/20',
          'https://maps.app.goo.gl/6LmibbYmbgiYfvAZ6'
        )}
      </Marker>
      <Marker position={betel}>
        {buildPopupContent('Betel - klub', 'plac Szczepański 3', 'https://maps.app.goo.gl/VuHGMT7GiUpX5rLx8')}
      </Marker>
      <Marker position={swietakrowa}>
        {buildPopupContent('Święta Krowa - klub', 'ul. Smoleńsk 22', 'https://maps.app.goo.gl/k7bW14KWZdBdj5SJ7')}
      </Marker>
    </MapContainer>
  )
}

export default MyMap
