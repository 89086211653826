import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Stories from 'react-insta-stories'
import SocialMedias from './SocialMedias'
// https://github.com/mohitk05/react-insta-stories#react-insta-stories

function getTransValue(key, t) {
  return key.includes('_') ? t(key) : key
}

function Story(props) {
  const cardRef = useRef(null)
  const [width, setWidth] = useState(0)
  const { t } = useTranslation()

  useEffect(() => {
    // Set the width of story container so it doesn't overflow
    // Ensure the element is rendered before trying to access its dimensions
    if (cardRef.current) {
      setWidth(cardRef.current.offsetWidth) // Get the width of the element
    }
  }, []) // Empty dependency array means this runs once after the initial render

  const stories = props.stories.map((story) => {
    return {
      url: require(`../assets/${props.folder}/${story.url}`),
      type: story.url.split('.').pop() === 'mp4' ? 'video' : 'image',
      muted: true,
      header: {
        heading: story.header.heading,
        subheading: getTransValue(story.header.subheading, t)
      },
      seeMore: ({ close }) => {
        return (
          <div className='see-more prose-invert' onClick={close}>
            <p className=''>{getTransValue(story.seeMore, t)}</p>
            {story.links ? <SocialMedias className='mt-8' linksArray={story.links} /> : null}
          </div>
        )
      }
    }
  })

  return (
    <div className='story'>
      <div ref={cardRef} className='bento-card'></div>
      <Stories stories={stories} defaultInterval={3000} width={width} loop={true} />
    </div>
  )
}

export default Story
