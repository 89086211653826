import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

// Components
import Layout from './components/Layout'
import EventInfo from './components/EventInfo'
import Calendar from './components/Calendar'
import './styles/App.css'
import MyMap from './components/Map'
import Story from './components/Story'
import Carousel from './components/Carousel'
// Import JSON data
import opening_party from './assets/opening_party.json'
import closing_party from './assets/closing_party.json'
// import story_wystawa from './assets/story_wystawa.json'
import story_impreza from './assets/story_impreza.json'
import exhibition_artists from './assets/exhibition_artists.json'
import Exhibition from './components/Exhibition'

function App() {
  const { t, i18n } = useTranslation()
  const [language, setLanguage] = useState('pl')
  const [justInfo, setJustInfo] = useState(false)

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng)
  }

  function toggleLanguage() {
    if (language === 'pl') {
      setLanguage('en')
      changeLanguage('en')
    } else {
      setLanguage('pl')
      changeLanguage('pl')
    }
  }

  return (
    <main className='main-content'>
      <div className='toggle-buttons' style={{ position: 'fixed', bottom: '0', right: '0', zIndex: '1000' }}>
        <button id='facebook' title='Facebook event'>
          <a href='https://www.facebook.com/events/1135531537778082' target='_blank' rel='noreferrer' className=''>
            <svg xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' width='22' viewBox='0 0 48 48'>
              <path fill='#039be5' d='M24 5A19 19 0 1 0 24 43A19 19 0 1 0 24 5Z'></path>
              <path
                fill='#fff'
                d='M26.572,29.036h4.917l0.772-4.995h-5.69v-2.73c0-2.075,0.678-3.915,2.619-3.915h3.119v-4.359c-0.548-0.074-1.707-0.236-3.897-0.236c-4.573,0-7.254,2.415-7.254,7.917v3.323h-4.701v4.995h4.701v13.729C22.089,42.905,23.032,43,24,43c0.875,0,1.729-0.08,2.572-0.194V29.036z'></path>
            </svg>
          </a>
        </button>
        <button id='language' title='Toogle language' onClick={() => toggleLanguage()}>
          {language === 'pl' ? '🇵🇱' : '🇬🇧'}
        </button>
        <button id='just-info' title='Toogle photos, videos and animations' onClick={() => setJustInfo(!justInfo)}>
          {justInfo ? '📱' : '📖'}
        </button>
      </div>

      <header id='header'>
        {/* TODO https://txt.svg.beauty/ */}
        <a href='/'>Matki Boskiej Zielnej 4.0</a>
      </header>
      <p className='intro-text'>{<Trans i18nKey='counter_desc' t={t} />}</p>
      {justInfo ? (
        <div className='calendar-layout'>
          <Calendar />
        </div>
      ) : (
        <Layout>
          <Carousel />
          <Calendar />
          <EventInfo eventData={opening_party} />
          <Exhibition eventData={exhibition_artists} />
          <MyMap />
          {/* <Story stories={story_wystawa} folder='wystawa' /> */}
          <EventInfo eventData={closing_party} />
          <Story stories={story_impreza} folder='impreza' />
          {/* <Story stories={story_various} folder='story_various' muted={mute} /> */}
        </Layout>
      )}
      <footer id='footer'>
        <span>
          {t('made_by')} <a href='https://linktr.ee/ehhhahah'>ehh hahah</a> 2024
        </span>
      </footer>
    </main>
  )
}

export default App
