import React from 'react'
import { useKeenSlider } from 'keen-slider/react'
import 'keen-slider/keen-slider.min.css'

const LOAD_RATE = 3000

function Arrow(props) {
  const disabled = props.disabled ? ' arrow--disabled' : ''
  return (
    <svg
      onClick={props.onClick}
      className={`arrow ${props.left ? 'arrow--left' : 'arrow--right'} ${disabled}`}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 24 24'>
      {props.left && <path d='M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z' />}
      {!props.left && <path d='M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z' />}
    </svg>
  )
}

function Carousel() {
  const [loaded, setLoaded] = React.useState([])
  const [currentSlide, setCurrentSlide] = React.useState(0)

  const images = [
    require('../assets/last_year/mbzs3-1.jpg'),
    require('../assets/last_year/mbzs3-4.jpg'),
    require('../assets/last_year/mbzs3-3.jpg'),
    require('../assets/last_year/mbzs3-2.jpg'),
    require('../assets/last_year/mbzs3-0.jpg'),
    require('../assets/last_year/mbzs3-5.jpg')
  ]

  const [sliderRef, instanceRef] = useKeenSlider(
    {
      animationEnded(s) {
        setCurrentSlide(s.track.details.rel)
      },
      loop: true,
      initial: 0
    },
    [
      (slider) => {
        let timeout
        let mouseOver = false
        function clearNextTimeout() {
          clearTimeout(timeout)
        }
        function nextTimeout() {
          clearTimeout(timeout)
          if (mouseOver) return
          timeout = setTimeout(() => {
            slider.next()
          }, LOAD_RATE)
        }
        slider.on('created', () => {
          slider.container.addEventListener('mouseover', () => {
            mouseOver = true
            clearNextTimeout()
          })
          slider.container.addEventListener('mouseout', () => {
            mouseOver = false
            nextTimeout()
          })
          nextTimeout()
        })
        slider.on('dragStarted', clearNextTimeout)
        slider.on('animationEnded', nextTimeout)
        slider.on('updated', nextTimeout)
      }
    ]
  )

  React.useEffect(() => {
    const new_loaded = [...loaded]
    new_loaded[currentSlide] = true
    setLoaded(new_loaded)
  }, [currentSlide])

  return (
    <div className='navigation-wrapper'>
      <div ref={sliderRef} className='keen-slider'>
        {images.map((image, idx) => (
          <div key={idx} className='keen-slider__slide lazy__slide'>
            <img alt='last year iamge' src={loaded[idx] ? image : ''} />
          </div>
        ))}
      </div>
      {loaded && instanceRef.current && (
        <div className='arrows'>
          <Arrow
            left
            onClick={(e) => e.stopPropagation() || instanceRef.current?.prev()}
            disabled={currentSlide === 0}
          />

          <Arrow
            onClick={(e) => e.stopPropagation() || instanceRef.current?.next()}
            disabled={currentSlide === instanceRef.current.track.details.slides.length - 1}
          />
        </div>
      )}
    </div>
  )
}

export default Carousel
