import React from 'react'
import { useTranslation } from 'react-i18next'
import SocialMedias from './SocialMedias'

function Exhibition(props) {
  const eventData = props.eventData
  const { t } = useTranslation()

  return (
    <div className='event-info'>
      <div className='with-padding -mb-8'>
        <p className='event-date'>
          {t('place')}: {eventData.place}
        </p>
        <h2 className='card-title'>{t(eventData.tr_title)}</h2>
        <p className='event-date'>
          {t('date')}: {eventData.date}
        </p>
        <p className='event-description'>{t(eventData.tr_description)}</p>
      </div>
      <div className='exhibition-arts-container'>
        {eventData.artists.map((artist, index) =>
          artist.tr_description ? (
            <div
              className='exhibition-art'
              key={index}
              onClick={() => document.getElementById(`my_modal_${artist.name}`).showModal()}>
              <span className='p-1'>
                <span className='artist-name'>{artist.name}</span>{' '}
              </span>
              {/* modal info */}
              <dialog id={`my_modal_${artist.name}`} className='modal'>
                <div className='modal-box'>
                  <span>{artist.links ? <SocialMedias linksArray={artist.links} /> : null}</span>
                  <h3 className='font-bold text-lg'>{artist.name}</h3>
                  <p className='py-4'>{t(artist.tr_description)}</p>
                  <div className='modal-action'>
                    <form method='dialog'>
                      {/* if there is a button in form, it will close the modal */}
                      <button className='btn'>{t('close')}</button>
                    </form>
                  </div>
                </div>
              </dialog>
            </div>
          ) : (
            <div className='exhibition-art' key={index}>
              <span className='p-1'>
                <span className='artist-name-no-link'>{artist.name}</span>{' '}
              </span>
            </div>
          )
        )}
      </div>

      <div className='with-padding pb-4'>
        <p className='minor-text'>{t('click_artist')}</p>
      </div>
    </div>
  )
}

export default Exhibition
